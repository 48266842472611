<template>
  <div class="h-full flex" data-test="order finish">
    <UltimaPackage
      v-if="currentStep === 'UltimaPackage'"
      :suggests="suggests"
      :packing="packing"
      :order_id="order_id"
      @done="nextStep"
    />
    <SelectBagsCountStage
      v-if="currentStep === 'SelectBagsCount'"
      :packing="packing"
      :suggests="suggests"
      :order_id="order_id"
      @done="onSelectBags"
    />
    <ConfirmAssembledProducts v-if="currentStep === 'ConfirmAssembledProducts'" :order="order" @done="nextStep" />
    <MaybeRover v-if="currentStep === 'maybe_rover'" :order_id="order_id" @done="onSetCourier" />
    <FindRover v-if="currentStep === 'FindRover'" :order="order" @done="nextStep" />
    <FinishStage v-if="currentStep === 'finish'" :order_id="order_id" @done="nextStep" />
    <!--Общие механики-->
    <RoverCard v-if="currentStep === 'rover'" :order="order" @ready-click="nextStep" />
    <FinishOrderCardQRCode v-if="currentStep === 'qrcode'" :order="order" @done="nextStep" />
  </div>
</template>

<script lang="ts" setup>
import ConfirmAssembledProducts from '@/fsd/widgets/order/finish-order/ConfirmAssembledProducts.vue';
import FinishStage from '@/fsd/widgets/order/finish-order/FinishStage.vue';
import FinishOrderCardQRCode from '@/fsd/widgets/order/finish-order/QRcode/finish-order-card-qrcode.vue';
import SelectBagsCountStage from '@/fsd/widgets/order/finish-order/SelectBags/SelectBagsCountStage.vue';
import UltimaPackage from '@/fsd/widgets/order/finish-order/UltimaPackage.vue';
import FindRover from '@/fsd/widgets/order/finish-order/rover/FindRover.vue';
import MaybeRover from '@/fsd/widgets/order/finish-order/rover/MaybeRover.vue';
import RoverCard from '@/fsd/widgets/order/finish-order/rover/rover-card.vue';
import Suggest from '@/models/Suggest';
import OrderOrder from '@/models/orders/OrderOrder';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { getItem } from '@/utils/localStorageHelper';
import { computed, onMounted, ref } from 'vue';

enum StepEnum {
  SelectBagsCount = 'SelectBagsCount',
  UltimaPackage = 'UltimaPackage',
  ConfirmAssembledProducts = 'ConfirmAssembledProducts',
  FindRover = 'FindRover',
  finish = 'finish',
  maybe_rover = 'maybe_rover',
  rover = 'rover',
  qrcode = 'qrcode',
}

interface Props {
  order_id: OrderOrder['order_id'];
  suggests: Suggest[];
  packing: Suggest[];
}

const props = defineProps<Props>();

const emits = defineEmits(['done']);

const order = computed(() => {
  return useOrders().orderById(props.order_id) as OrderOrder | undefined;
});
const waitingRover = computed<boolean>(() => Boolean(order.value?.attr.maybe_rover && !order.value.courier));
const steps = computed(() => {
  const steps: StepEnum[] = [];
  if (!order.value) return steps;
  const expCheckQr = useUser().experimentByName(experiments.exp_check_qr);

  if (
    props.suggests.some(s => s.isItemSuggest && s.item?.isUltimaParcel) &&
    props.packing.some(p => p.isUltimaPackage && p.status === 'request')
  ) {
    steps.push(StepEnum.UltimaPackage);
  }
  // Показываем этап с выбором пакетов, только если есть не закрытые саджесты на упаковку либо если саджестов на
  // упаковку вообще нет. В этом случае пользователь увидит сообщение о том, что на складе не настроен сэмплинг
  if (props.packing.length === 0 || props.packing.some(p => p.status === 'request')) {
    steps.push(StepEnum.SelectBagsCount);
  }
  if (waitingRover.value) {
    steps.push(StepEnum.maybe_rover);
  }

  if (order.value.conditions.confirm_assembled_products) {
    steps.push(StepEnum.ConfirmAssembledProducts);
  }

  if (order.value.isRover) {
    steps.push(StepEnum.FindRover);
    steps.push(StepEnum.rover);
  }

  if (expCheckQr) {
    steps.push(StepEnum.qrcode);
  }

  steps.push(StepEnum.finish);

  return steps;
});

const currentStep = ref<StepEnum>(steps.value[0]);
const nextStep = (step?: StepEnum) => {
  if (step) {
    currentStep.value = step;
    return;
  }
  const currentIndex = steps.value.findIndex(s => currentStep.value === s);
  if (currentIndex >= steps.value.length - 1) {
    // * это был последний шаг
    emits('done');
    return;
  }
  currentStep.value = steps.value[currentIndex + 1];
};
const onSelectBags = () => {
  if (!order.value) return;
  if (order.value.conditions.confirm_assembled_products) {
    return nextStep(StepEnum.ConfirmAssembledProducts);
  }
  if (order.value.isRover) {
    return nextStep(StepEnum.FindRover);
  }
  if (waitingRover.value) {
    return nextStep(StepEnum.maybe_rover);
  }
  nextStep(StepEnum.finish);
};

const onSetCourier = () => {
  if (!order.value) return;
  if (order.value.isRover) {
    return nextStep(StepEnum.FindRover);
  }
  nextStep(StepEnum.finish);
};

onMounted(() => {
  if (!order.value) return;

  if (getItem('roverScan', order.value.order_id)) {
    const roverIndex = steps.value.findIndex(s => s === StepEnum.rover);
    if (roverIndex !== -1) {
      currentStep.value = StepEnum.rover;
    }
  }
});
</script>
