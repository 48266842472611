import { logger } from '@/temp/plugins/logs';

const getMarkNameStart = (name: string) => `${name}.start`;
const getMarkNameEnd = (name: string) => `${name}.end`;

export const markStart = (name: string) => {
  performance.clearMarks(getMarkNameEnd(name));
  performance.mark(getMarkNameStart(name));
};

export const markEnd = (name: string) => {
  performance.mark(getMarkNameEnd(name));
};

export const getDuration = (name: string) => {
  try {
    const markStartName = getMarkNameStart(name);
    const markEndName = getMarkNameEnd(name);

    const [start] = performance.getEntriesByName(markStartName, 'mark');
    if (!start) return undefined;

    const [end] = performance.getEntriesByName(markEndName, 'mark');
    if (!end) markEnd(name);

    const duration = performance.measure(name, markStartName, markEndName)?.duration;
    performance.clearMeasures(name);
    performance.clearMarks(markEndName);
    performance.clearMarks(markStartName);
    return duration;
  } catch (e) {
    logger.error(e);
  }
};
