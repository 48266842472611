<template>
  <div
    class="flex w-full"
    :class="{
      'flex-wrap': !CounterIcon,
      'opacity-60': disabled,
    }"
  >
    <CounterField v-if="!isTrueWeight" :min="min" :max="max" :value="count" @change="onChange" @show-calc="showCalc" />
    <CounterFieldTrueWeight v-else :value="count" @show-calc="showCalc" />
    <div v-if="CounterIcon">
      <UiButton
        data-test="counter continue"
        is-icon
        class="!h-14 !w-14 ml-2 disabled:bg-secondary disabled:fill-text-secondary fill-text-primary"
        :disabled="count > max || count < min || disabled"
        @click="confirmInput"
      >
        <CounterIcon class="fill-inherit" />
      </UiButton>
    </div>
    <UiButton
      v-else-if="btnText"
      data-test="counter continue"
      class="!h-14 mt-2 disabled:text-secondary"
      background-color="secondary"
      :is-hold="isHold"
      :timeout="btnTimeout"
      :disabled="count > max || count < min || disabled"
      @click="confirmInput"
    >
      <Title4>
        {{ btnText }}
      </Title4>
    </UiButton>
    <Calc v-if="calc.visible.value" :is-true-weight="isTrueWeight" :min="min" :max="max" @close="calc.hide" />
    <CalcWithHistory v-if="calcWithHistory.visible.value" :min="min" :max="max" @close="calcWithHistory.hide" />
  </div>
</template>

<script setup lang="ts">
import CounterFieldTrueWeight from '@/fsd/entities/counter/counter-field-true-weight.vue';
import CounterField from '@/fsd/entities/counter/counter-field.vue';
import IconCheck from '@/fsd/shared/icons/icon-check.vue';
import IconNext from '@/fsd/shared/icons/icon-next.vue';
import { useComponent } from '@/hooks/useComponent';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product, { TypeAccountingEnum } from '@/models/Product';
import { ModeService } from '@/services/mode.service';
import { useProducts } from '@/store/modules/products';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import CalcWithHistory from '@/ui/common/keyboard/calc-with-history.vue';
import Calc from '@/ui/common/keyboard/calc.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { Component, computed, ref, watch } from 'vue';

const iconPaths: Record<string, Component> = {
  check: IconCheck,
  next: IconNext,
};

interface CounterProps {
  min?: number;
  max?: number;
  value?: number;
  product_id?: string;
  iconKey?: keyof typeof iconPaths;
  btnText?: string;
  btnTimeout?: number;
  needHistory?: boolean;
  disabled?: boolean;
}

const props = withDefaults(defineProps<CounterProps>(), {
  min: 0,
  max: Number.MAX_SAFE_INTEGER,
  value: 0,
  btnText: '',
  btnTimeout: 0,
  needHistory: false,
  disabled: false,
});

const productsStore = useProducts();
const calc = useComponent<void, string>();
const calcWithHistory = useComponent<void, string>();
const count = ref<number>(props.value);

const emits = defineEmits<{
  (e: 'change', value: number): void;
  (e: 'confirm', value: number): void;
}>();

const CounterIcon = computed<Component | undefined>(() => {
  return props.iconKey ? iconPaths[props.iconKey] : undefined;
});

const product = computed<Product | undefined>(() => {
  return productsStore.productById(props.product_id);
});

const isTrueWeight = computed<boolean>(() => {
  return product?.value?.type_accounting === TypeAccountingEnum.true_weight;
});

const needInputByScan = computed<boolean>(() => {
  return Boolean(!props.disabled && props.product_id);
});

const isHold = computed<boolean>(() => {
  return !ModeService.isDevelopment();
});

function onChange(value: number) {
  if (value <= props.max && value >= props.min) {
    count.value = value;
    emits('change', count.value);
  }
}

async function showCalc() {
  if (props.needHistory && !isTrueWeight.value) {
    const value = await calcWithHistory.asyncShow();
    if (value) {
      onChange(+value);
    }
  } else {
    const value = await calc.asyncShow();
    if (value) {
      const newValue = isTrueWeight.value ? +value * 1000 : +value;
      onChange(newValue);
    }
  }
}

const { needBarcodeRequest } = useRequestBarcode(
  async barcode => {
    let value = 0;
    if (isTrueWeight.value) {
      const weight = Product.weightFromBarcode(barcode);
      barcode = barcode.replace(barcode.slice(-6, -1), '00000');
      barcode = barcode.slice(0, -1);
      barcode += Product.getControlNumber(barcode);
      value = weight;
    } else {
      value = 1;
    }
    try {
      const barcodeProduct = await productsStore.getProductByBarcode(barcode);
      if (barcodeProduct.product_id === props.product_id) {
        onChange(count.value + value);
      } else {
        Notifications.error.micro($gettext('Отсканирован другой товар'));
      }
      return true;
    } catch (error) {
      Notifications.error.micro($gettext('Отсканирован неверный баркод'));
      return true;
    }
  },
  { immediate: false },
);

function confirmInput() {
  needBarcodeRequest.value = false;
  emits('confirm', count.value);
}

watch(
  () => props.value,
  (val, oldVal) => {
    if (val !== oldVal) {
      count.value = val!;
    }
  },
);

watch(
  needInputByScan,
  isNeedInputByScan => {
    needBarcodeRequest.value = isNeedInputByScan;
  },
  { immediate: true },
);
</script>
