import { useDevtoolsStore } from '@/devtools/store/devtoolsModule';

import apiLogger from '@/fsd/data/api/api-logger';
import Item from '@/models/Item';
import BaseOrder from '@/models/orders/BaseOrder';
import ControlCheckOrder from '@/models/orders/ControlCheckOrder';
import { createOrder } from '@/models/orders/OrderFactory';
import Product from '@/models/Product';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import {
  BarcodeRequest,
  CheckContractorRequest,
  CheckTrueMarkRequest,
  CompleteControlCheckRequest,
  ConfigsStoreLoadRequest,
  ControlCheckRequest,
  ItemPackageClassSaveRequest,
  ItemsRequest,
  OrderAcceptanceItemsRequest,
  OrderAcceptanceRequest,
  OrderAcceptanceResultCountRequest,
  OrderAckRequest,
  OrderAssetsWriteoffRequest,
  OrderChangeStatusRequest,
  OrderCheckRequest,
  OrderChildrenRequest,
  OrderDoneBox2ShelfRequest,
  OrderDoneCheckMoreRequest,
  OrderDoneCheckRequest,
  OrderDoneShelf2BoxRequest,
  OrderExecuterRequest,
  OrderGetClientPhoneRequest,
  OrderGetPortionsRequest,
  OrderGetSuggestRequest,
  OrderLoadOrderRequest,
  OrderMoveRequest,
  OrderRepackingRequest,
  OrdersAllRequest,
  OrderShipmentItemsRequest,
  OrderSignalRequest,
  OrdersRequest,
  OrderSuggestsRequest,
  OrderWriteoffRequest,
  ProductBugRequest,
  ProductsRequest,
  QRActionsRequest,
  RepairTaskLoadRequest,
  ShelvesRequest,
  StockAvailableRequest,
  SupportSearchRequest,
  UserAssignDeviceRequest,
  UserFreeDeviceRequest,
  UserOptionsSaveRequest,
  UserUpgradeRequest,
} from '@/services/requests';
import {
  BarcodeResponse,
  CheckContractorResponse,
  CompleteControlCheckResponse,
  ConfigsStoreLoadResponse,
  ControlCheckResponse,
  ItemPackageClassSaveResponse,
  ItemsResponse,
  OrderAcceptanceItemsResponse,
  OrderAcceptanceResponse,
  OrderAcceptanceResultCountResponse,
  OrderAckResponse,
  OrderAssetsWriteoffResponse,
  OrderChangeStatusResponse,
  OrderCheckResponse,
  OrderChildrenResponse,
  OrderDoneBox2ShelfResponse,
  OrderDoneCheckMoreResponse,
  OrderDoneCheckResponse,
  OrderDoneShelf2BoxResponse,
  OrderExecuterResponse,
  OrderGetClientPhoneResponse,
  OrderGetPortionsResponse,
  OrderGetSuggestResponse,
  OrderLoadOrderResponse,
  OrderMoveResponse,
  OrderQueueResponse,
  OrderRepackingResponse,
  OrderShipmentItemsResponse,
  OrderSignalResponse,
  OrdersResponse,
  OrderSuggestsResponse,
  OrderWriteoffResponse,
  ProductBugResponse,
  ProductsResponse,
  PutObjectS3Response,
  QrActionResponse,
  RepairTaskLoadResponse,
  ShelvesResponse,
  StocksAvailableResponse,
  SupportSearchResponse,
  SupportUrlResponse,
  UploadFileResponse,
  UserAssignDeviceResponse,
  UserDowngradeResponse,
  UserOptionsResponse,
  UserOptionsSaveResponse,
  UserUpgradeResponse,
  VersionResponse,
} from '@/services/response';
import { stub } from '@/services/stubForUserStats';
import { getImgUrl } from '@/services/utils';
import { useIndicators } from '@/store/indicators';
import { useOrders } from '@/store/modules/orders';
import { useUser } from '@/store/modules/user';
import { $gettext } from '@/temp/plugins/gettext';
import { logger } from '@/temp/plugins/logs';
import { FileMeta, UploadImageData } from '@/types/attachment';
import { microNotify } from '@/utils';
import axios, { AxiosRequestConfig } from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';

export let imgUrl = localStorage.getItem('img');

export const setImgUrl = (url: string) => {
  imgUrl = url;
};
export const axiosForApi = axios.create();
axiosBetterStacktrace(axiosForApi);
axiosForApi.defaults.baseURL = localStorage.getItem('api') || '/api/tsd/';

axiosForApi.interceptors.request.use(request => {
  request.headers.setContentType('application/json');
  const token = useUser().token;
  if (token) {
    request.headers.setAuthorization(token);
  }
  if (request.data?.order_id) {
    request.params = request.params
      ? { ...request.params, orderId: request.data.order_id }
      : { orderId: request.data.order_id };
  }
  request.metadata = { startTime: Date.now() };
  useIndicators().startRequest(request);
  return request;
});

axiosForApi.interceptors.response.use(
  response => {
    if (response.config.metadata?.startTime) {
      response.config.metadata.endTime = Date.now();
      response.duration = response.config.metadata.endTime - response.config.metadata.startTime;
      apiLogger(response);
    }
    useDevtoolsStore().pushRequest(response);
    useIndicators().removeRequest(response.config);
    return response;
  },
  async error => {
    const needLog = error => {
      // https://st.yandex-team.ru/LAVKADEV-10817  Ручка assign-device по утрам спамит 403 с кодом ER_ACCESS.
      // Смысловой нагрузки они не несут, но возбуждают алерты, поэтому в игнор
      if (error.config.url.indexOf('assign-device') !== -1 && error.response.data.code === 'ER_ACCESS') {
        return false;
      }
      return true;
    };

    if (needLog(error)) {
      logger.error(error, {
        source: 'API_ERROR',
        additional: {
          requestUrl: error.config.url,
          requestData: error.config.data,
          responseStatus: error.response?.status,
          responseData: error.response?.data,
        },
      });
    }

    const devtools = useDevtoolsStore();

    if (error.config.url.indexOf('ping') === -1) {
      if (error.response) devtools.pushRequest(error.response);
      useIndicators().receivedError();
    }
    if (error.response) {
      error.config.metadata.endTime = new Date();
      error.duration = error.config.metadata.endTime - error.config.metadata.startTime;
      devtools.pushRequest(error.response);
    }
    if (error.response && error.response.status === 401) {
      // не ходим в freeDevice, так как если пойдем без токена - получим 401
      // и войдем в бесконечный луп разлогинов, поэтому только локально подчищаем
      await useUser().logout(false);
    }
    // разлогиниваем пользователя, если он отключен
    if (error.response && error.response.status === 403 && error.response.data.code === 'ER_USER_DISABLED') {
      await useUser().logout(false);
    }
    if (axios.isCancel(error)) {
      return error;
    }
    return Promise.reject(error);
  },
);

export const api = {
  order: {
    done: {
      shelf2box: async (payload: OrderDoneShelf2BoxRequest) => {
        const response = await axiosForApi.post<OrderDoneShelf2BoxResponse>('order/done/shelf2box', payload);
        response.data.suggests = response.data.suggests?.map(s => new Suggest(s));
        return response;
      },
      box2shelf: async (payload: OrderDoneBox2ShelfRequest) => {
        const response = await axiosForApi.post<OrderDoneBox2ShelfResponse>('order/done/box2shelf', payload);
        response.data.suggests = response.data.suggests?.map(s => new Suggest(s));
        return response;
      },
      check: async (payload: OrderDoneCheckRequest) => {
        const response = await axiosForApi.post<OrderDoneCheckResponse>('order/done/check', payload);
        response.data.suggests = response.data.suggests?.map(s => new Suggest(s));
        return response;
      },
      check_more: async (payload: OrderDoneCheckMoreRequest) => {
        const response = await axiosForApi.post<OrderDoneCheckMoreResponse>('order/done/check_more', payload);
        response.data.suggests = response.data.suggests?.map(s => new Suggest(s));
        return response;
      },
    },
    ack: async (payload: OrderAckRequest) => {
      return axiosForApi.post<OrderAckResponse>('order/ack', payload);
    },
    check: async (payload: OrderCheckRequest) => {
      return axiosForApi.post<OrderCheckResponse>('order/check', payload);
    },
    writeoff: async (payload: OrderWriteoffRequest) => {
      return axiosForApi.post<OrderWriteoffResponse>('order/writeoff', payload);
    },
    change_status: async (payload: OrderChangeStatusRequest) => {
      return axiosForApi.post<OrderChangeStatusResponse>('order/change_status', payload);
    },
    suggests: async (payload: OrderSuggestsRequest) => {
      const response = await axiosForApi.post<OrderSuggestsResponse>('order/suggests', payload);
      response.data.suggests = response.data.suggests.map(s => new Suggest(s));
      return response;
    },
    acceptance: async (payload: OrderAcceptanceRequest) => {
      return axiosForApi.post<OrderAcceptanceResponse>('order/acceptance', payload);
    },
    acceptance_items: async (payload: OrderAcceptanceItemsRequest) => {
      return axiosForApi.post<OrderAcceptanceItemsResponse>('order/acceptance_items', payload);
    },
    shipment_items: async (payload: OrderShipmentItemsRequest) => {
      return axiosForApi.post<OrderShipmentItemsResponse>('order/shipment-items', payload);
    },
    move: async (payload: OrderMoveRequest) => {
      return axiosForApi.post<OrderMoveResponse>('order/move', payload);
    },
    signal: async (payload: OrderSignalRequest) => {
      const permit = useUser().permitByName('order_signals');
      if (!permit || !Array.isArray(permit) || !permit.includes(payload.signal)) {
        microNotify.error(
          $gettext('У вас отсутствуют права на отправку сигнала: %{signal}', {
            signal: payload.signal,
          }),
        );
        throw new Error('Access denied');
      }
      return axiosForApi.post<OrderSignalResponse>('order/signal', payload);
    },
    executer: async (payload: OrderExecuterRequest) => {
      return axiosForApi.post<OrderExecuterResponse>('order/executer', payload);
    },
    children: async (payload: OrderChildrenRequest) => {
      return axiosForApi.post<OrderChildrenResponse>('order/children', payload);
    },
    acceptance_result_count: async (option: OrderAcceptanceResultCountRequest) => {
      return axiosForApi.post<OrderAcceptanceResultCountResponse>('order/acceptance_result_count', option);
    },
    get_portions: async (payload: OrderGetPortionsRequest) => {
      return axiosForApi.post<OrderGetPortionsResponse>('order/get_portions', payload);
    },
    load_order: async (payload: OrderLoadOrderRequest) => {
      return axiosForApi.post<OrderLoadOrderResponse>('order/load_order', payload);
    },
    assets_writeoff: async (payload: OrderAssetsWriteoffRequest) => {
      return axiosForApi.post<OrderAssetsWriteoffResponse>('order/assets_writeoff', payload);
    },
    repacking: async (payload: OrderRepackingRequest) => {
      return axiosForApi.post<OrderRepackingResponse>('order/repacking', payload);
    },
    control_check: async (payload: ControlCheckRequest) => {
      const response = await axiosForApi.post<ControlCheckResponse>('order/control_check', payload);
      response.data.order = createOrder(response.data.order) as ControlCheckOrder;
      useOrders().setOrders([response.data.order]);
      return response;
    },
    complete_control_check: async (payload: CompleteControlCheckRequest) => {
      return await axiosForApi.post<CompleteControlCheckResponse>('order/complete_control_check', payload);
    },
    get_suggest: async (payload: OrderGetSuggestRequest) => {
      const response = await axiosForApi.post<OrderGetSuggestResponse>('order/get_suggest', payload);
      response.data.suggest = response.data.suggest.map(s => new Suggest(s));
      return response;
    },
    get_client_phone: async (payload: OrderGetClientPhoneRequest) => {
      return axiosForApi.post<OrderGetClientPhoneResponse>('order/get_client_phone', payload);
    },
  },
  repair_task: {
    load: async (payload: RepairTaskLoadRequest) => {
      return axiosForApi.post<RepairTaskLoadResponse>('repair_task/load', payload);
    },
  },
  stock: {
    available: async (payload: StockAvailableRequest) => {
      return axiosForApi.post<StocksAvailableResponse>('stock/available', payload);
    },
  },
  support: {
    url: async () => {
      return axiosForApi.post<SupportUrlResponse>('support/url');
    },
    product_bug: async (payload: ProductBugRequest) => {
      return axiosForApi.post<ProductBugResponse>('support/product_bug', payload);
    },
    // ручка не работает локально. только на тестинге или в проде.
    // поэтому тестов на нее нет, нужно быть аккуратнее при изменениях!
    search: async (payload: SupportSearchRequest) => {
      const response = await axiosForApi.post<SupportSearchResponse>('support/search', payload);
      logger.error('support-search', {
        additional: {
          requestUrl: 'support/search',
          requestData: payload,
          responseStatus: response.status,
          responseData: response.data,
        },
      });
      return response;
    },
  },
  user: {
    assign_device: async (payload: UserAssignDeviceRequest) => {
      return await axiosForApi.post<UserAssignDeviceResponse>('user/assign-device', payload);
    },
    options: async () => {
      return await axiosForApi.get<UserOptionsResponse>('user/options');
    },
    options_save: async (payload: UserOptionsSaveRequest) => {
      return await axiosForApi.post<UserOptionsSaveResponse>('user/options_save', payload);
    },
    upgrade: async (payload: UserUpgradeRequest) => {
      return axiosForApi.post<UserUpgradeResponse>('user/upgrade', payload);
    },
    downgrade: async () => {
      return axiosForApi.post<UserDowngradeResponse>('user/downgrade', {});
    },
    async user_stats() {
      return stub;
    },
    async logout(payload: UserFreeDeviceRequest) {
      try {
        return await axiosForApi.post('user/free-device', payload);
      } catch (err: any) {
        // игнорим все 4** ошибки
        if (err?.response?.status >= 400 && err?.response?.status < 500) return;
        throw err;
      }
    },
  },
  ping: {
    api: async (timeout: number = 5000) => {
      return axiosForApi.get('ping', { timeout });
    },
    event: async (timeout: number = 5000) => {
      return axiosForApi.get('/api/ev/ping', { timeout, baseURL: '/' });
    },
    polka: async (timeout: number = 5000) => {
      return axiosForApi.get('ping', { timeout, baseURL: '/' });
    },
  },
  orders: async (payload: OrdersRequest, config?: AxiosRequestConfig) => {
    const response = await axiosForApi.post<OrdersResponse>('orders', payload, config);
    // преобразуем полученные данные в модели
    response.data.orders = response.data.orders.map(createOrder);
    return response;
  },
  // вернет по ид ордера в любом статусе
  ordersAll: async (order_ids: BaseOrder['order_id'][], config?: AxiosRequestConfig) => {
    const payload: OrdersAllRequest = {
      order_ids,
      all: true,
    };
    const response = await axiosForApi.post<OrdersResponse>('orders', payload, config);
    response.data.orders = response.data.orders.map(createOrder);
    return response;
  },
  // Использовалась для проверки наличия товара в ассортименте поставщика перед отправкой сигнала more_product
  // сейчас не используется
  check_contractor: async (payload: CheckContractorRequest) => {
    return axiosForApi.post<CheckContractorResponse>('check_contractor', payload);
  },
  check_true_mark: async (payload: CheckTrueMarkRequest) => {
    return axiosForApi.post('check_true_mark', payload);
  },
  qr_action: async <T>(payload: QRActionsRequest) => {
    return axiosForApi.post<QrActionResponse<T>>('qr_action', payload);
  },
  barcode: async (payload: BarcodeRequest) => {
    return axiosForApi.post<BarcodeResponse>('barcode', payload);
  },
  products: async (payload: ProductsRequest, config?: AxiosRequestConfig) => {
    const response = await axiosForApi.post<ProductsResponse>('products', payload, config);
    response.data.products = response.data.products.map(product => {
      product.images = product.images.map(image => getImgUrl(image));
      return product;
    });
    response.data.products = response.data.products.map(p => new Product(p));
    return response;
  },
  shelves: async (payload: ShelvesRequest, config?: AxiosRequestConfig) => {
    const response = await axiosForApi.post<ShelvesResponse>('shelves', payload, config);
    response.data.shelves = response.data.shelves.map(shelf => new Shelf(shelf));
    return response;
  },
  items: {
    save: async (payload: ItemPackageClassSaveRequest, config?: AxiosRequestConfig) => {
      const response = await axiosForApi.post<ItemPackageClassSaveResponse>('items/save', payload, config);
      response.data.result = new Item(response.data.result);
      return response;
    },
    load: async (payload: ItemsRequest, config?: AxiosRequestConfig) => {
      const response = await axiosForApi.post<ItemsResponse>('items', payload, config);
      response.data.items = response.data.items.map(p => new Item(p));
      return response;
    },
  },
  version: async () => {
    const ts = Date.now();
    return await axiosForApi.get<VersionResponse>('/version.json', {
      params: { ts: ts },
      baseURL: window.location.origin,
    });
  },
  files: {
    // создаст файлик с ключом 'temporary_files/${external_id}/${filename}'
    put_object_s3: async (payload: UploadImageData) => {
      return axiosForApi.post<PutObjectS3Response>('/files/put_object_s3', payload);
    },
    get_object_s3: async (key: string) => {
      return axiosForApi.get<any>('/files/get_object_s3', {
        params: {
          bucket: 'wms-files',
          key,
        },
      });
    },
    upload: async (payload: FileMeta) => {
      const form = new FormData();
      form.append('external_id', payload.external_id);
      if (payload.storage) form.append('storage', payload.storage);
      if (payload.file_group_name) form.append('file_group_name', payload.file_group_name);
      form.append('data', payload.data.file, payload.data.filename);

      return axiosForApi.post<UploadFileResponse>('/files/upload', form);
    },
  },
  configs: {
    store: {
      load: async (payload: ConfigsStoreLoadRequest, config?: AxiosRequestConfig) => {
        return await axiosForApi.post<ConfigsStoreLoadResponse>('/configs/store/load', payload, config);
      },
    },
  },
  orders_queue: async () => {
    return await axiosForApi.post<OrderQueueResponse>('orders_queue', {});
  },
};
