import { CurrenciesEnum } from '@/temp/constants/currencies';
import { BaseModel } from 'sjs-base-model';

interface Store {
  estatus: 'processing';
  currency: CurrenciesEnum;
  //experiments
  options: { [key: string]: boolean };
  title: string;
  options_setup: {
    exp_mc_hammer_timetable: [];
    sherlock_border: number;
  };
}

export enum BrandingEnum {
  yandex = 'yandex',
  yango = 'yango',
}

interface DomainConfigs {
  account?: 'yandex' | string;
  branding?: BrandingEnum;
  domain?: string;
  support_chat_url?: string;
}

export default class Constants extends BaseModel {
  public backend_version: string = '';
  public store: Store = {
    estatus: 'processing',
    title: '',
    currency: CurrenciesEnum.RUB,
    options: {},
    options_setup: {
      exp_mc_hammer_timetable: [],
      sherlock_border: 2,
    },
  };
  public domain_configs: DomainConfigs = {};

  constructor(data: any) {
    super({ expand: true });
    this.update(data);
  }
}
