<template>
  <Layout>
    <template #header>
      <OrderRetailHeaderDetails
        :suggest="suggest"
        :order="order"
        :collected="collected.length"
        @close="$emit('close')"
        @remove-suggest="$emit('removeSuggest', suggest)"
        @open-suggest-menu="$emit('openSuggestMenu', suggest)"
      />
    </template>
    <template #default>
      <SuggestDetails :collected="collected" :product="suggest.product" :suggest="suggest" />
    </template>
    <template #footer>
      <OrderRetailFooterDetails
        :suggest="suggest"
        :collected="collected.length"
        @suggest-done="$emit('suggestDone', suggest)"
        @remove-products="$emit('removeProducts', suggest)"
        @change-products="$emit('changeProducts', suggest.suggest_id)"
      />
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { SuggestDetails } from '@/fsd/entities/suggest';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import OrderOrderRetail from '@/models/orders/OrderOrderRetail';
import { useProducts } from '@/store/modules/products';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import Layout from '@/ui/common/layout.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import { computed, defineEmits, defineProps } from 'vue';
import OrderRetailFooterDetails from './order-retail-footer-details.vue';
import OrderRetailHeaderDetails from './order-retail-header-details.vue';

const productsStore = useProducts();
const { showLoader } = useLoader();

interface SuggestDetailsProps {
  order: OrderOrderRetail;
  suggest_id: Suggest['suggest_id'];
  collected: string[];
}

const props = defineProps<SuggestDetailsProps>();

const emits = defineEmits<{
  (e: 'close'): void;
  (e: 'removeSuggest', suggest: Suggest): void;
  (e: 'openSuggestMenu', suggest: Suggest): void;
  (e: 'updateCollected', suggest: Suggest, barcodes: string[]): void;
  (e: 'removeProducts', suggest: Suggest): void;
  (e: 'suggestDone', suggest: Suggest): void;
  (e: 'changeProducts', suggest_id: Suggest['suggest_id']): void;
}>();

const suggest = computed<Suggest>(() => {
  return props.order.suggestById(props.suggest_id)!;
});

const addProduct = async (suggest: Suggest, barcode: string): Promise<void> => {
  const confirm = await Notifications.confirmCenter({
    title: $gettext('Добавить еще этого товара?'),
    text: $gettext('Этот товар уже полностью собран. Вы уверены, что хотите добавить ещё этого товара?'),
    decline: $gettext('Отмена'),
    ok: $gettext('Добавить'),
  });

  if (confirm && suggest.result_count) {
    emits('updateCollected', suggest, [barcode]);
  }
};

useRequestBarcode(async barcode => {
  const { closeLoader } = showLoader();
  try {
    const product = await productsStore.getProductByBarcode(barcode);
    if (suggest.value.product_id !== product.product_id) {
      Notifications.error.micro($gettext('Отсканирован другой товар'));
      return true;
    }

    if (suggest.value.isFull && !suggest.value.product?.isTrueWeight && props.order.isReplaceItemsMethod) {
      closeLoader();
      await addProduct(suggest.value, barcode);
      return true;
    }

    if (suggest.value.status === SuggestStatusEnum.done && suggest.value.isFull) {
      Notifications.error.micro($gettext('Данный продукт уже собран'));
      return true;
    }
    emits('updateCollected', suggest.value, [barcode]);

    return true;
  } catch (error) {
    Notifications.error.micro($gettext('Отсканирован неверный баркод'));
    return true;
  } finally {
    closeLoader();
  }
});
</script>
