import Event from '@/models/events/Event';
import { TypePackageClass } from '@/models/Item';
import BaseOrder from '@/models/orders/BaseOrder';
import Product from '@/models/Product';
import Report from '@/models/Report';
import Shelf from '@/models/Shelf';
import Suggest from '@/models/Suggest';
import { UserLanguageEnum } from '@/models/User';
import { TrashReasonsEnum } from '@/types/TrashReasonsEnum';

export interface OrderCheckRequest {
  order_id: string;
  mode: 'check_product_on_shelf';
  update_valids: boolean;
  parent?: [string];
  check: {
    shelf_id: string;
    product_id: string;
  };
}

export interface OrderDoneCheckRequest {
  // все перемешано в кучу в спеке бека, по возможности делить на части!!!
  suggest_id: string;
  status?: 'done' | 'cancel';
  count?: number;
  valid?: string;
  reason?: {
    code: ReasonsEnum | LotReasonsEnum;
    count: number;
  };
  weight?: number;
  product_id?: string;
}

export type OrderDoneCheckMoreRequest = CheckMoreRequestDefault | CheckMoreRequestWeight | CheckMoreRequestError;
export interface CheckMoreRequestDefault {
  suggest_id: string;
  product_id: string;
  count?: number;
  status?: 'done';
  valid?: string | null;
  reason?: {
    code: Omit<ReasonsEnum, 'CHANGE_WEIGHT'>;
  };
}
export interface CheckMoreRequestWeight {
  suggest_id: string;
  product_id: string;
  status?: 'done';
  weight: number;
  valid?: string | null;
  reason?: {
    code: Omit<ReasonsEnum, 'CHANGE_WEIGHT'>;
  };
}
export interface CheckMoreRequestError {
  suggest_id: string;
  status: 'error';
}

export interface OrderAcceptanceResultCountRequest {
  order_id: string;
  product_id: string;
}

export enum ReasonsEnum {
  CHANGE_COUNT = 'CHANGE_COUNT',
  CHANGE_VALID = 'CHANGE_VALID',
  CHANGE_COUNT_VALID = 'CHANGE_COUNT_VALID',
  CHANGE_WEIGHT = 'CHANGE_WEIGHT',
}
export enum LotReasonsEnum {
  DAMAGED_WITHOUT_ACCESS_TO_ATTACHMENT = 'DAMAGED_WITHOUT_ACCESS_TO_ATTACHMENT', // # лот поврежден
  DAMAGED_WITH_ACCESS_TO_ATTACHMENT = 'DAMAGED_WITH_ACCESS_TO_ATTACHMENT', //# лот имеет доступ ко вложению
  SEAL_IS_MISSING = 'SEAL_IS_MISSING', // нет пломбы
}

export interface OrderGetPortionsRequest {
  shelf_id: string;
  product_id: string;
}

export interface OrderLoadOrderRequest {
  order_ids: string[];
}

export interface OrderMoveRequest {
  order_id: string;
  move: {
    stock_id?: string;
    move_order_id?: string;
    product_id?: string;
    item_id?: string;
    count: number;
    src_shelf_id: string;
    dst_shelf_id: string;
    reason?: {
      code: TrashReasonsEnum;
      comment?: string;
    };
  }[];
}

export interface OrderAssetsWriteoffRequest {
  external_id: string;
  repair_task_external_id: string;
  repair_task_source: string;
  required: BaseOrder['required'];
}

export interface RepairTaskLoadRequest {
  external_id: string;
  source: string;
}
export interface CheckTrueMarkRequest {
  true_mark: string;
  order_id?: BaseOrder['order_id'];
}

export interface QRActionsRequest {
  sign: string;
}

export interface OrdersRequest {
  order_ids?: BaseOrder['order_id'][];
  statuses?: BaseOrder['status'][];
}
export interface OrdersAllRequest {
  order_ids: BaseOrder['order_id'][];
  all: true;
}

export interface OrderAckRequest {
  order_id: BaseOrder['order_id'];
}

export type OrderDoneShelf2BoxSuccessReasonCode = 'CHANGE_COUNT' | 'CHANGE_VALID' | 'CHANGE_COUNT_VALID';

export interface OrderDoneShelf2BoxSuccessRequest {
  suggest_id: Suggest['suggest_id'];
  status?: 'done' | 'cancel';
  count?: number;
  valid?: string;
  reason?: {
    code: OrderDoneShelf2BoxSuccessReasonCode;
    count?: number;
  };
  weight?: number;
  product_id?: Product['product_id'];
  true_mark?: string;
  barcodes?: { barcode: string; count: number }[];
}

export interface OrderDoneShelf2BoxErrorRequest {
  suggest_id: Suggest['suggest_id'];
  status: 'error';
  reason: {
    // как понимаю, это код для нехватки товаров
    // в count то, сколько товара не хватает
    code: 'PRODUCT_ABSENT';
    count: number;
  };
}

export type OrderDoneShelf2BoxRequest = OrderDoneShelf2BoxSuccessRequest | OrderDoneShelf2BoxErrorRequest;

export type Box2ShelfTrashReason =
  | 'CHANGE_COUNT'
  | 'CHANGE_VALID'
  | 'CHANGE_COUNT_VALID'
  | 'TRASH_DAMAGE'
  | 'TRASH_TTL'
  | 'TRASH_DECAYED'
  | 'TRASH_ORDINANCE'
  | 'TRASH_MOL'
  | 'TRASH_ACCIDENT'
  | 'TRASH_TRUE_MARK_DAMAGE'
  | 'TRASH_TRUE_MARK_REFUND';

export interface OrderDoneBox2ShelfSuccessRequest {
  suggest_id: Suggest['suggest_id'];
  status?: 'done' | 'cancel';
  // При исправлении done возможны
  // * `CHANGE_COUNT` - исправляем количество
  // * `CHANGE_VALID` - исправляем СГ
  // * `CHANGE_COUNT_VALID` - исправляем количество и СГ
  // При закрытии саджеста на полку списания можно указать
  // причины
  // * `TRASH_DAMAGE` - повреждён
  // * `TRASH_DECAYED` - потерял товарный вид (протух)
  // * `TRASH_TTL` - вышел срок годности
  // * `TRASH_ORDINANCE` - списание по распоряжению (на полку списания)
  // * `TRASH_MOL` - разбил/сломал/сьел (на полку списания)
  // * `TRASH_ACCIDENT` - поломка оборудования (на полку списания)
  // * `TRASH_TRUE_MARK_DAMAGE` - повреждена марка ЧЗ (на полку списания)
  // * `TRASH_TRUE_MARK_REFUND` - возврат товара ЧЗ (на полку списания)
  reason?: {
    code: Box2ShelfTrashReason | 'LIKE_SHELF' | 'CHANGE_COUNT' | 'TTL';
    // в спеке нет, но если убрать, то все сломается!
    count: number;
  };

  weight?: number;
  product_id?: Product['product_id'];
  count?: number;
  true_mark?: string;
  valid?: string;
  barcodes?: { barcode: string; count: number }[];
}

export interface OrderDoneBox2ShelfErrorRequest {
  suggest_id: Suggest['suggest_id'];
  status: 'error';
  reason: {
    code: 'SHELF_IS_FULL';
  };
}

export interface OrderDoneBox2ShelfLikeShelfRequest {
  suggest_id: Suggest['suggest_id'];
  status: 'error';
  reason: {
    code: 'LIKE_SHELF';
    shelf_id: Shelf['shelf_id'];
  };
}

export type OrderDoneBox2ShelfRequest =
  | OrderDoneBox2ShelfSuccessRequest
  | OrderDoneBox2ShelfErrorRequest
  | OrderDoneBox2ShelfLikeShelfRequest;

export interface OrderWriteoffRequest {
  order_id: BaseOrder['order_id'];
  check: {
    shelves: Shelf['shelf_id'][];
  };
}

export interface OrderChangeStatusCompleteRequest {
  order_id: BaseOrder['order_id'];
  status?: 'complete';
  attr?: {
    truck_temperature: number;
  };
}

export interface OrderChangeStatusFailedRequest {
  order_id: BaseOrder['order_id'];
  status: 'failed';
  reason: 'incomplete' | 'comment';
  comment?: string;
}

export type OrderChangeStatusRequest = OrderChangeStatusCompleteRequest | OrderChangeStatusFailedRequest;

export interface OrderSuggestsRequest {
  order_id: BaseOrder['order_id'];
}

export interface OrderAcceptanceRequest {
  external_id: string;
  required: { item_id: string }[];
}

export interface OrderShipmentItemsRequest {
  external_id: string;
  courier_qr_code?: string;
}

export interface OrderAcceptanceItemsRequest {
  external_id: string;
  courier_qr_code: string;
}

export type Signal =
  | 'acceptance_agree'
  | 'more_product'
  | 'sale_stowage'
  | 'shortfall'
  | 'complete_suggests'
  | 'rover_open_hatch'
  | 'confirm_assembled_products'
  | 'stat'
  | 'edoc'
  | 'edoc_check'
  | 'complete_final_stage'
  | 'next_stage'
  | 'add_product'
  | 'edoc_cancel';

export interface OrderSignalRequest {
  order_id: BaseOrder['order_id'];
  signal: Signal;
  data?: any;
}

export interface OrderExecuterRequest {
  order_id: BaseOrder['order_id'];
  link?: string[];
  unlink?: string[];
}

export interface CheckContractorRequest {
  product_id: string;
  order_id: string;
}

export interface OrderChildrenRequest {
  product_id: string;
  cursor?: string;
}

export interface BarcodeRequest {
  barcode: string | number;
  order_id?: BaseOrder['order_id'];
}

export interface ProductsRequest {
  ids: string[];
}

export interface StockAvailableRequest {
  shelf_id?: string | string[];
  product_id?: string | string[];
  item_id?: string | string[];
}

export interface OrderRepackingRequest {
  external_id: string;
  repacking: {
    product_id: string;
    shelf_id: string;
  };
}
export interface ShelvesRequest {
  ids: string[];
}
export interface ItemsRequest {
  ids: string[];
}

export interface ItemPackageClassSaveRequest {
  item_id: string;
  data: {
    package_class?: TypePackageClass;
  };
}

export interface UserAssignDeviceRequest {
  device: string;
  barcode: string | number;
}

export interface UserOptionsSaveRequest {
  lang: UserLanguageEnum;
}

export interface UserUpgradeRequest {
  pin: string;
}

export interface UserFreeDeviceRequest {
  device: string;
}

export interface EventTakeRequest {
  state?: string;
  timeout?: number;
  keys: Event['key'][];
  user_id?: string;
  order_id?: string;
}

export interface ControlCheckRequest {
  order_id: string;
  shelf_barcode: string;
  date?: string;
}
export interface CompleteControlCheckRequest {
  order_id: string;
  report: Report[];
}

export interface ProductBugRequest {
  bug_type: string;
  product_id: string;
  comment?: string;
  s3_attachments?: { file_meta_id: string }[];
}

export interface ConfigsStoreLoadRequest {
  store_id: string;
  group: string;
  name?: string;
}

export interface OrderGetSuggestRequest {
  order_id: string;
  suggest_id: string[];
}

export interface OrderGetClientPhoneRequest {
  order_id: string;
}

//https://a.yandex-team.ru/arcadia/taxi/backend-py3/services/chatterbox/chatterbox/api/schema/chatterbox.yaml?rev=r9883558#L1408
export interface SupportSearchRequest {
  // ручка может принимать любые параметры, подробности в https://st.yandex-team.ru/LAVKADEV-6555
  order_id?: string;
  product_id?: string;
}
