<template>
  <div class="w-screen h-screen z-10 fixed top-0 left-0 bg-white">
    <Layout>
      <template #header>
        <Bar :icons="{ left: 'back' }" @close-click="$emit('close')" />
      </template>
      <template #default>
        <div class="flex flex-col gap-1.5 bg-element">
          <div class="flex flex-col gap-4 bg-main rounded-b-3xl px-4 pb-4">
            <Title2 class="font-bold">
              {{ $gettext('Отсканируйте проблемные марки Честного Знака') }}
            </Title2>
            <Body2>
              {{ $gettext('Марки товаров, которые не получается собрать в заказ, чтобы сообщить о них в поддержку') }}
            </Body2>
            <Body1 class="font-bold">
              {{ $gettext('Товар') }}
            </Body1>
            <ReportProductCard :suggest="suggest" />
          </div>
          <div class="flex flex-col gap-2 bg-main rounded-t-3xl px-4 pt-4">
            <ReportItem
              v-for="(s, i) in suggests"
              :key="s.suggest_id"
              :suggest="s"
              :num="i + 1"
              :status="trueMarkStatus(s)"
              @no-scan="setCanScanTrueMark"
              @can-scan="setCanScanTrueMark"
            />
          </div>
        </div>
      </template>
      <template #footer>
        <LayoutFooter class="flex-col items-center gap-2">
          <div class="flex flex-col gap-0.5 items-center">
            <Caption1 class="font-medium">{{
              $gettext('%{num} из %{all}', { num: String(numChecked), all: String(suggests.length) })
            }}</Caption1>
            <Caption1 color="semantic-text-minor">{{ $gettext('Отсканировано проблемных марок') }}</Caption1>
          </div>
          <UiButton
            v-if="numChecked === suggests.length"
            data-test="order report-menu pause-btn"
            class="w-full"
            background-color="primary"
            @click="createReport"
          >
            {{ $gettext('Сообщить в поддержку') }}
          </UiButton>
        </LayoutFooter>
      </template>
    </Layout>
  </div>
</template>

<script setup lang="ts">
import { api } from '@/fsd/data/api/api.service';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import Product from '@/models/Product';
import Suggest from '@/models/Suggest';
import BaseOrder from '@/models/orders/BaseOrder';
import TrueMarkSuggestWrapper from '@/models/suggest/TrueMarkSuggestWrapper';
import { useProducts } from '@/store/modules/products';
import { checkTrueMarkErrorsCode } from '@/temp/constants/translations/checkTrueMarkErrorsCode';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import Bar from '@/ui/common/bar/bar.vue';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import { useLoader } from '@/ui/common/loader/useLoader';
import Body2 from '@/ui/common/typo/body-2.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { computed, defineEmits, defineProps, ref } from 'vue';
import ReportItem from './ReportItem.vue';
import ReportProductCard from './ReportProductCard.vue';
import { ERROR_NO_SCAN_MARK, REPORT_ITEM_STATUS } from './constants';

const { showLoader } = useLoader();
const productsStore = useProducts();

interface ReportMessage {
  message: string;
  chatMetaData: {
    order_id: BaseOrder['order_id'];
    product_id: Suggest['product_id'];
    doc_number: BaseOrder['external_id'];
    error_code: string;
  };
}

interface TrueMarkMergeReportProps {
  suggest: Suggest | TrueMarkSuggestWrapper;
  order: BaseOrder;
}

const props = defineProps<TrueMarkMergeReportProps>();
const emits = defineEmits<{
  (e: 'report', report: ReportMessage): void;
  (e: 'close'): void;
}>();

const trueMarkErrorCodes = ref<Record<Suggest['suggest_id'], string>>({});

const product = computed<Product | undefined>(() => props.suggest.product);

const suggests = computed<Suggest[]>(() => {
  if (TrueMarkSuggestWrapper.isTrueMarkSuggestWrapper(props.suggest)) {
    return props.suggest.suggests.filter(s => !s.isDone);
  } else {
    return [props.suggest];
  }
});

const numChecked = computed(() => {
  return Object.keys(trueMarkErrorCodes.value).length;
});

const trueMarkStatus = (suggest: Suggest): REPORT_ITEM_STATUS => {
  const errorCode = trueMarkErrorCodes.value[suggest.suggest_id];
  if (errorCode === ERROR_NO_SCAN_MARK) {
    return REPORT_ITEM_STATUS.error;
  } else if (errorCode) {
    return REPORT_ITEM_STATUS.ready;
  }
  return REPORT_ITEM_STATUS.waiting;
};

const setTrueMark = (errorCode: string): void => {
  const suggest = suggests.value.find(s => !trueMarkErrorCodes.value[s.suggest_id]);

  if (suggest) {
    trueMarkErrorCodes.value[suggest.suggest_id] = errorCode;
  }
};

const setCanScanTrueMark = (id: Suggest['suggest_id'], errorCode?: string): void => {
  if (errorCode) {
    trueMarkErrorCodes.value[id] = errorCode;
  } else {
    delete trueMarkErrorCodes.value[id];
  }
};

const createReportMessage = (): string => {
  const title = $gettext('Здравствуйте! Проблема со сборкой заказа %{doc_number}', {
    orderNumber: props.order.attr.doc_number,
  });

  const productTitle = $gettext('Товар: %{product_id} %{product_title}', {
    product_id: product.value?.external_id || '',
    product_title: product.value?.title || '',
  });

  const errorsList = Object.values(trueMarkErrorCodes.value)
    .map(err => $gettext('ошибка %{error_code}', { error_code: err }))
    .join('\n');

  return `${title}\n${productTitle}\n${errorsList}`;
};

const createReport = async (): Promise<void> => {
  const confirmed = await Notifications.confirmBottom({
    title: $gettext('Отправить сообщение в чат поддержки?'),
  });
  if (!confirmed) {
    return;
  }
  emits('report', {
    message: createReportMessage(),
    chatMetaData: {
      order_id: props.order.order_id,
      product_id: props.suggest.product_id,
      doc_number: props.order.external_id,
      error_code: 'INVALID_TRUE_MARK',
    },
  });
};

useRequestBarcode(async trueMark => {
  const { closeLoader } = showLoader();
  try {
    const product = await productsStore.getProductByBarcode(trueMark);
    if (product && product.product_id === product?.product_id) {
      await api.check_true_mark({ true_mark: trueMark, order_id: props.order.order_id });
      Notifications.success.micro($gettext('Марка Честный Знак сканируется без ошибок'));
    } else {
      Notifications.modal({
        title: $gettext('Отсканирована марка от другого продукта'),
      });
    }
    return true;
  } catch (error: any) {
    if (error?.isAxiosError && error?.response) {
      const errCode = error.response.data.code;
      if (errCode === 'ER_INVALID_TRUE_MARK') {
        Notifications.modal({
          title: checkTrueMarkErrorsCode.ER_INVALID_TRUE_MARK,
        });
        return true;
      } else {
        setTrueMark(error.response.data.code);
        return false;
      }
    } else {
      Notifications.error.micro($gettext('Произошла ошибка при сканировании Марки, повторите попытку'));
      return true;
    }
  } finally {
    closeLoader();
  }
});
</script>
