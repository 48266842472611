<template>
  <ScaleContainer
    :need-scale="status === REPORT_ITEM_STATUS.waiting || status === REPORT_ITEM_STATUS.error"
    class="rounded-2xl p-4 bg-card flex flex-col gap-1.5"
    @long-press="itemMenu.asyncShow()"
  >
    <Body2 class="font-medium">{{ $gettext('Марка Честного Знака') }}</Body2>
    <Caption1 class="font-medium" color="semantic-text-minor">{{
      $gettext('Товар %{num}', { num: String(num) })
    }}</Caption1>
    <ReportItemStatus :status="status" />
  </ScaleContainer>
  <SideMenu
    v-if="menuConfig && itemMenu.visible.value"
    :title="suggest.product?.title"
    :menu-config="menuConfig"
    @close="itemMenu.hide"
  />
</template>

<script setup lang="ts">
import { useComponent } from '@/hooks/useComponent';
import Suggest from '@/models/Suggest';
import { $gettext } from '@/temp/plugins/gettext';
import SideMenu from '@/ui/common/menu/side-menu.vue';
import type { MenuItemConfig } from '@/ui/common/menu/types';
import ScaleContainer from '@/ui/common/scale-container/scale-container.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import { computed, defineProps } from 'vue';
import ReportItemStatus from './ReportItemStatus.vue';
import { ERROR_NO_SCAN_MARK, REPORT_ITEM_STATUS } from './constants';

interface ReportItemProps {
  suggest: Suggest;
  num: number;
  status: REPORT_ITEM_STATUS;
}

const itemMenu = useComponent();

const props = defineProps<ReportItemProps>();

const emits = defineEmits<{
  (event: 'noScan', suggest_id: Suggest['suggest_id'], error: typeof ERROR_NO_SCAN_MARK): void;
  (event: 'canScan', suggest_id: Suggest['suggest_id']): void;
}>();

const menuConfig = computed(() => {
  const config: MenuItemConfig[] = [];

  const noScanBtn: MenuItemConfig = {
    buttonText: $gettext('Не могу отсканировать марку'),
    buttonSecondText: $gettext('Не сканируется или нет'),
    dataTest: 'true-mark no-scan',
    iconKey: 'trueMark',
    onClick: () => {
      emits('noScan', props.suggest.suggest_id, ERROR_NO_SCAN_MARK);
      itemMenu.hide();
    },
    condition: () => props.status === REPORT_ITEM_STATUS.waiting,
  };
  config.push(noScanBtn);

  const canScanBtn: MenuItemConfig = {
    buttonText: $gettext('Марку можно отсканировать'),
    dataTest: 'true-mark can-scan',
    iconKey: 'trueMark',
    onClick: () => {
      emits('canScan', props.suggest.suggest_id);
      itemMenu.hide();
    },
    condition: () => props.status === REPORT_ITEM_STATUS.error,
  };
  config.push(canScanBtn);

  return config;
});
</script>
