import { api } from '@/fsd/data/api/api.service';
import { SherlockStatus } from '@/fsd/widgets/order/RequiredCard/sherlock/types';
import Suggest, { SuggestTagEnum } from '@/models/Suggest';
import OrderOrder from '@/models/orders/OrderOrder';
import { Signal } from '@/services/requests';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { useSuggests } from '@/store/modules/suggests';
import { useUser } from '@/store/modules/user';
import { availableForSherlock, experiments } from '@/temp/constants';
import { logger } from '@/temp/plugins/logs';
import { useLoader } from '@/ui/common/loader/useLoader';
import { computed, ref } from 'vue';

export const useSherlock = (order_id: OrderOrder['order_id'], suggest_id: Suggest['suggest_id']) => {
  const { showLoader } = useLoader();
  const sherlockStatus = ref<SherlockStatus>('maybe');

  const order = computed(() => {
    return useOrders().orderById(order_id);
  });
  const suggest = computed(() => {
    return useSuggests().getSuggest(order_id, suggest_id);
  });
  const checkSherlockMustSkip = (): boolean => {
    if (!suggest.value || !order.value) return true;
    // эксп отключен, шерлока не будет
    if (!useUser().experimentByName(experiments.exp_sherlock)) return true;
    // это не саджест на взять с полки
    if (suggest.value.type !== 'shelf2box') return true;
    // саджест уже выполнен, нет смысла проверять
    if (suggest.value.status !== 'request') return true;
    // это посылка, не проверяем
    if (suggest.value?.isItemSuggest) return true;
    // саджест на кухню, шерлок не нужен
    if (suggest.value.conditions.tags?.includes(SuggestTagEnum.kitchen)) return true;
    // уже есть сигнал по этому саджесту
    if (order.value.signals?.find(s => s.data?.suggest_id === suggest_id)) return true;

    return sherlockStatus.value === 'skip';
  };

  const checkNeedSherlock = async (): Promise<boolean> => {
    if (checkSherlockMustSkip()) return false;
    if (!suggest.value) return false;
    try {
      // спросить о количестве остатков, если available<count
      const available = await useProducts().fetchAvailable(suggest.value.product_id);
      const availableOnShelf = available?.find(item => item.shelf_id === suggest.value!.shelf_id);
      const sherlockCount = useUser().sherlockBorder || availableForSherlock;
      //спрашиваем остатки только если остатки есть, остатков больше чем минимальное кол-во для запроса и после выполнения саджеста остатков станет меньше
      if (
        availableOnShelf &&
        availableOnShelf.count > sherlockCount &&
        availableOnShelf.count - suggest.value.count! <= sherlockCount
      ) {
        sherlockStatus.value = 'need';
        return true;
      } else {
        sherlockStatus.value = 'skip';
        return false;
      }
    } catch (e) {
      console.error(e);
      return false;
    }
  };

  const sendSherlockSignal = async (): Promise<void> => {
    const { closeLoader } = showLoader();
    try {
      const payload = {
        order_id,
        signal: 'shortfall' as Signal,
        data: { suggest_id, source: 'sherlock' },
      };
      const response = await api.order.signal(payload);
      // логируем конкретный склад
      if (order.value?.store_id === '7feb7448165e4a8a9ca991adbda0599c000200010001')
        logger.error('sherlock-log', {
          method: 'signal',
          type: 'api',
          source: 'product-card.vue',
          additional: {
            requestUrl: 'order/signal',
            requestData: payload,
            responseStatus: response.status,
            responseData: response.data,
          },
        });
      closeLoader();
    } catch (e) {
      closeLoader();
      logger.error(e, { method: 'signal', type: 'api', source: 'product-card.vue' });
    }
  };

  sherlockStatus.value = checkSherlockMustSkip() ? 'skip' : 'maybe';

  return { checkSherlockMustSkip, checkNeedSherlock, sherlockStatus, sendSherlockSignal };
};
