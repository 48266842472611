<template>
  <Bar
    :order="order"
    :caption="caption"
    :text="text"
    :progress-config="progressConfig"
    :need-progress="!isCollectDone"
    :total="suggestsForCollect.length"
    :menu-config="suggestMenuConfig"
    :menu-title="suggest.product?.title"
    :menu-subtitle="collectedCountSubtitle"
    @close-click="toHomePage"
  />
  <ReportMenu
    v-if="order && suggest && reportMenu.visible.value"
    :suggest="suggest"
    :order="order"
    @close="reportMenu.hide"
    @report="reportAboutProblem"
  />
  <TrueMarkReport
    v-if="trueMarkReport.visible.value && !expTrueMarkMerged"
    :suggest="suggest"
    :order="order"
    @report="trueMarkReport.hide"
    @close="trueMarkReport.hide"
  />
  <TrueMarkMergeReport
    v-if="trueMarkReport.visible.value && expTrueMarkMerged"
    :suggest="suggest"
    :order="order"
    @report="trueMarkReport.hide"
    @close="trueMarkReport.hide"
  />
  <OrderMissingIngradient
    v-if="ingradient.visible.value"
    :product_id="ingradient.props.value"
    @close="ingradient.hide"
    @done="ingradient.hide"
  />
</template>
<script setup lang="ts">
import { api } from '@/fsd/data/api/api.service';
import {
  getBox2ShelfSuggests,
  getFullDoneSuggests,
  getSuggestsByStatus,
} from '@/fsd/entities/suggest/tools/suggestsFilters';
import AboutOrderModal from '@/fsd/features/order/ui/modals/AboutOrderModal.vue';
import { callAction } from '@/fsd/shared/ui/action';
import { TrueMarkMergeReport } from '@/fsd/widgets/TrueMarkMergeReport';
import { useComponent } from '@/hooks/useComponent';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import { OrderTargetEnum } from '@/models/orders/BaseOrder';
import OrderOrder from '@/models/orders/OrderOrder';
import TrueMarkSuggestWrapper from '@/models/suggest/TrueMarkSuggestWrapper';
import { useOrders } from '@/store/modules/orders';
import { useProducts } from '@/store/modules/products';
import { ChatMetaData, SupportTypeEnum, useUser } from '@/store/modules/user';
import { experiments } from '@/temp/constants';
import { $gettext, $ngettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import Bar from '@/ui/common/bar/bar.vue';
import { getDefaultProgressConfig } from '@/ui/common/bar/getDefaultProgressConfig';
import { ProgressConfig } from '@/ui/common/bar/types';
import { useLoader } from '@/ui/common/loader/useLoader';
import { MenuItemConfig } from '@/ui/common/menu/types';
import { getWeightToView } from '@/ui/common/suggest-card/formatters/count-formatter';
import OrderMissingIngradient from '@/ui/order/missing-ingradient/missing-ingradient.vue';
import ReportMenu from '@/ui/order/report-menu/report-menu.vue';
import TrueMarkReport from '@/ui/order/report-menu/true-mark-report.vue';
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { Collected } from './types';

interface Props {
  order_id: OrderOrder['order_id'];
  suggest: Suggest | TrueMarkSuggestWrapper;
  collected?: Collected;
}

const props = withDefaults(defineProps<Props>(), { collected: () => ({ count: 0 }) });
const emits = defineEmits(['startBarcodeRequest', 'stopBarcodeRequest']);
defineExpose({
  showReportMenu: () => reportMenu.show(),
});

const router = useRouter();
const { showLoader } = useLoader();
const expTrueMarkMerged = useUser().experimentByName(experiments.exp_true_mark_merged);
const reportMenu = useComponent();
const trueMarkReport = useComponent<void, { message: string; chatMetaData: ChatMetaData }>();
const ingradient = useComponent<string, string[]>();

const order = computed(() => {
  return useOrders().orderById(props.order_id) as OrderOrder | undefined;
});

const collectedCountSubtitle = computed(() => {
  const isTrueWeightProduct = props.suggest.product?.isTrueWeight;
  if (isTrueWeightProduct) {
    return $gettext('Количество %{n} из %{all} кг.', {
      n: String(props.collected?.count),
      all: String(props.suggest.count),
    });
  } else {
    return $gettext('Количество %{n} из %{all} шт.', {
      n: String(props.collected?.count),
      all: String(props.suggest.count),
    });
  }
});

const suggests = computed(() => {
  if (!order.value) return [];
  return order.value.suggests;
});
const suggestsForCollect = computed(() => {
  const nonPackingSuggests = suggests.value.filter(suggest => !suggest.isPackaging);
  if (order.value?.target === OrderTargetEnum.canceled) {
    return nonPackingSuggests.filter(s => s.status === 'request' || s.type === 'box2shelf');
  }
  return nonPackingSuggests;
});
const remainingSuggestsCount = computed(() => {
  if (!order.value) return 0;
  return getSuggestsByStatus(suggestsForCollect.value, [SuggestStatusEnum.request, SuggestStatusEnum.blocked]).length;
});
const caption = computed(() => {
  if (!order.value) return '';

  if (remainingSuggestsCount.value === 0) {
    return '';
  }

  if (order.value.isCanceled) {
    return $gettext('Возврат товаров на полки');
  }
  return $gettext('Заказ %{number}', { number: order.value.orderNumberForView });
});
const text = computed(() => {
  if (!order.value) return '';
  if (remainingSuggestsCount.value === 0) {
    if (order.value.target === OrderTargetEnum.canceled) {
      return $gettext('Все товары возвращены');
    }
    if (order.value.isOrderPaused) {
      return $gettext('Ожидайте решения поддержки');
    }
    return '';
  }

  if (order.value.target === 'canceled') {
    return $ngettext(
      'Осталось вернуть %{request} из %{all} товар',
      'Осталось вернуть %{request} из %{all} товаров',
      remainingSuggestsCount.value,
      { request: String(remainingSuggestsCount.value), all: String(suggestsForCollect.value.length) },
    );
  }
  return $ngettext(
    'Осталось %{request} из %{all} товар',
    'Осталось %{request} из %{all} товаров',
    remainingSuggestsCount.value,
    {
      request: String(remainingSuggestsCount.value),
      all: String(suggestsForCollect.value.length),
    },
  );
});
const progressConfig = computed((): ProgressConfig[] | undefined => {
  if (!order.value) return;
  if (order.value.target === OrderTargetEnum.canceled) {
    return [
      {
        count: getFullDoneSuggests(getBox2ShelfSuggests(suggestsForCollect.value)).length,
        color: 'green',
      },
    ];
  }
  return getDefaultProgressConfig(suggestsForCollect.value);
});
const isCollectDone = computed(() => {
  if (!order.value) return true;
  if (order.value.target !== OrderTargetEnum.complete) return false;
  return remainingSuggestsCount.value === 0;
});
const suggestMenuConfig = computed(() => {
  const menuConfig: MenuItemConfig[] = [];

  if (isCollectDone.value && order.value?.isRover) {
    const openHatchBtn: MenuItemConfig = {
      buttonText: $gettext('Открыть крышку'),
      onClick: openHatch,
    };
    menuConfig.push(openHatchBtn);
  }

  if (!isCollectDone.value) {
    if (!props.suggest) return menuConfig;
    //  для Обычных ТОЛЬКО обычная кнопка
    if (!props.suggest?.product?.components.length) {
      const sendReportBtn: MenuItemConfig = {
        buttonText: $gettext('Сообщить о проблеме'),
        onClick: onHasProblemClick,
        dataTest: 'order report-btn',
        buttonTextColor: 'red-toxic',
        iconKey: 'cancel',
      };
      menuConfig.push(sendReportBtn);
    }

    const errorInCardBtn: MenuItemConfig = {
      buttonText: $gettext('Ошибка в карточке товара'),
      buttonSecondText: $gettext('Изображение, описание, другое'),
      dataTest: 'order error-card',
      iconKey: 'pencilToLine',
      onClick: async () => {
        await Notifications.report({
          title: $gettext('Ошибка в карточке товара'),
          productId: props.suggest.product_id,
        });
      },
      condition: () => useUser().experimentByName(experiments.exp_product_bug_ticket),
    };
    menuConfig.push(errorInCardBtn);

    const sendTrueMarkReportBtn: MenuItemConfig = {
      buttonText: $gettext('Проблема с Честным Знаком'),
      buttonSecondText: $gettext('Ошибка сканирования, отсутствует'),
      onClick: createTrueMarkTicketInSupportChat,
      dataTest: 'order true-mark report-btn',
      iconKey: 'trueMark',
      condition: () => props.suggest.conditions?.need_true_mark && useUser().supportType === SupportTypeEnum.chatterbox,
    };

    menuConfig.push(sendTrueMarkReportBtn);

    // для инградиентных продуктов - ТОЛЬКО кнопка сообщить о нехватке инградиентов. Проверяем, что это НЕ блюдо, что готовит повар (для повара будет статус blocked)
    if (props.suggest?.product?.components.length && props.suggest.status === 'request') {
      const missingIngredientBtn: MenuItemConfig = {
        buttonText: $gettext('Ингредиент отсутствует'),
        onClick: missingIngradient,
        dataTest: 'order missing-ingradient-btn',
      };
      menuConfig.push(missingIngredientBtn);
    }
  }

  if (order.value?.isExternalOrder) {
    const aboutOrderBtn: MenuItemConfig = {
      buttonText: $gettext('О заказе'),
      dataTest: 'menu about-order',
      iconKey: 'info',
      isAboutOrder: true,
      onClick: showInfoAboutOrder,
    };
    menuConfig.push(aboutOrderBtn);
  }

  return menuConfig;
});

const toHomePage = () => {
  return router.push({ name: 'home' });
};
const openHatch = async () => {
  const confirmed = await Notifications.confirmCenter({
    title: $gettext('Вы уверены, что хотите открыть ровер?'),
  });
  if (!confirmed) return;
  const { closeLoader } = showLoader();
  try {
    await api.order.signal({
      order_id: props.order_id,
      signal: 'rover_open_hatch',
    });
    closeLoader();
    Notifications.success.micro($gettext('Сигнал на открытие крышки отправлен'));
  } catch (e) {
    closeLoader();
    console.error(e);
    Notifications.error.micro($gettext('Произошла ошибка при открытии ровера'));
    throw e;
  }
};
const onHasProblemClick = () => {
  switch (useUser().supportType) {
    case SupportTypeEnum.sapsan:
    case SupportTypeEnum.chatterbox:
      reportMenu.show();
      break;
    case SupportTypeEnum.auto_edit:
      autoEditOrder();
      break;
  }
};
const autoEditOrder = async () => {
  if (!props.suggest) {
    await Notifications.modal({ title: $gettext('Ожидайте решения поддержки') });
    return;
  }
  //  спросить сколько не хватает

  let count = 0;

  if (props.suggest.product && props.suggest.product.type_accounting === 'true_weight') {
    // в потенциале может понадобится выставлять max = max_count. сейчас бек не разрешает
    count = (await Notifications.counter({
      title: $gettext('Какое количество товара не удалось собрать?'),
      text: $gettext('Если товар закончился, укажите %{count}', {
        count: getWeightToView(props.suggest.count!),
      }),
      suggest: props.suggest,
      max: props.suggest.count!,
    })) as number;
  } else {
    count = (await Notifications.counter({
      title: $gettext('Сколько товара не хватает?'),
      text: $gettext('Сколько единиц товара, необходимого для заказа, не хватает на полке?'),
      suggest: props.suggest,
      max: props.suggest.count!,
    })) as number;
  }

  // пользователь закрыл модалку
  if (!count) {
    return;
  }
  const { closeLoader } = showLoader(undefined, props.order_id);
  try {
    //   заслать shortFall
    await api.order.signal({
      order_id: props.order_id,
      signal: 'shortfall',
      data: { suggest_id: props.suggest.suggest_id, count },
    });
    // вручную грузим ордер, чтобы обновление по ивентам не перетерло флажок паузы, потом ставим паузу
    await useOrders()
      .loadOrders([props.order_id])
      .then(() => {
        useOrders().pause({ order_id: props.order_id });
      });
    closeLoader();
    await toHomePage();
  } catch (e) {
    closeLoader();
    console.error(e);
  }
};
const reportAboutProblem = (option: { message: string; chatMetaData: ChatMetaData; count: number }) => {
  sendShortFall({ count: option.count });
  createTicketInSupportChat(option.message, option.chatMetaData);
};
const sendShortFall = async (options?: { componentIds?: string[]; true_mark?: boolean; count?: number }) => {
  const order_id = props.order_id;
  // Если сейчас отображается саджест, а не проблема, то шлем сигнал
  if (!props.suggest || !order.value) return;
  const hasInSignals = order.value.signals?.find(s => s.data?.suggest_id === props.suggest.suggest_id);
  if (hasInSignals) return;
  // почему то сей запрос может висеть по 30 сек. пользователи не могут просто так взять и не тыкать в экран
  // всем, что под руку попадется. поэтому показываем им картинки дальше, а запрос уж как нибудь сам
  try {
    await api.order.signal({
      order_id,
      signal: 'shortfall',
      data: {
        suggest_id: props.suggest.suggest_id,
        source: 'moriarty',
        product_ids: options?.componentIds,
        true_mark: options?.true_mark,
        count: options?.count,
      },
    });
    await useOrders().loadOrders([order_id]);
    await useOrders().pause({ order_id });
  } catch (e) {
    console.error(e);
  }
};
const createTicketInSupportChat = async (message: string, chatMetaData: ChatMetaData) => {
  try {
    const hasTicket = await checkExistSupportChatTicket(chatMetaData.order_id, chatMetaData.product_id);
    await useOrders().pause({ order_id: props.order_id });
    if (hasTicket) {
      await Notifications.modal({
        title: $gettext('Обращение по данной позиции уже создано!'),
        text: $gettext('Дождитесь обработки обращения поддержкой'),
      });
      await toHomePage();
      return;
    }
    // initial_message_text - сообщение в чатик
    // initial_message_autosend - автоотправка сообщения в саппорт
    // ticket_subject - тема сообщения

    useUser().setChatMetaData({
      ...chatMetaData,
      initial_message_text: message,
      initial_message_autosend: 'true',
      ticket_subject: 'Отсутствует позиция в заказе',
    });
    await router.push({ name: 'support' });
  } catch (error) {
    console.error(error);
  }
};
const checkExistSupportChatTicket = async (order_id: string, product_id: string) => {
  const { closeLoader } = showLoader();
  try {
    const { data } = await api.support.search({ order_id, product_id });

    const activeTasks = data.tasks.filter(
      t => !['closed', 'archived', 'archive_in_progress', 'ready_to_archive'].includes(t.status),
    );

    return activeTasks.length > 0;
  } catch (e) {
    console.error(e);
    return false;
  } finally {
    closeLoader();
  }
};

const createTrueMarkTicketInSupportChat = async () => {
  if (!props.suggest) return;
  emits('stopBarcodeRequest');

  const options = await trueMarkReport.asyncShow();
  // была нажата кнопка close и сработал emit('close')
  if (!options) {
    emits('startBarcodeRequest');
    return;
  }

  sendShortFall({ true_mark: true });
  createTicketInSupportChat(options.message, options.chatMetaData);
};

const missingIngradient = async () => {
  if (!props.suggest?.product || !order.value) return;
  const missingIds = await ingradient.asyncShow(props.suggest.product_id);
  //  если ничего нет, то пользователь просто закрыл окно, поэтому ничего не делаем
  if (!missingIds?.length) return;

  if (useUser().supportType === SupportTypeEnum.auto_edit) {
    const count = (await Notifications.counter({
      title: $gettext('Сколько напитков не удалось приготовить?'),
      text: $gettext('Пожалуйста, укажите количество'),
      max: props.suggest.count!,
    })) as number;
    sendShortFall({ componentIds: missingIds, count });
    return;
  }
  const chatMetaData = {
    order_id: props.order_id,
    product_id: props.suggest.product_id,
    doc_number: order.value.external_id,
    component_ids: missingIds.toString(),
  };

  const ingradients = '\n' + missingIds.map(id => '-' + useProducts().productById(id)?.title + ';').join('\n');
  const message = $gettext(
    'Здравствуйте! Проблема с заказом %{docNumber}, для товара «%{product}» не хватает ингредиентов: %{ingradients}',
    { docNumber: order.value.attr.doc_number, product: props.suggest.product.title, ingradients },
  );

  sendShortFall({ componentIds: missingIds });
  createTicketInSupportChat(message, chatMetaData);
};

const showInfoAboutOrder = () => {
  callAction(AboutOrderModal, { order_id: props.order_id });
};
</script>
