<template>
  <Layout>
    <div class="bg-day-cardDivider h-full flex flex-col gap-1.5">
      <Sector class="rounded-t-none pt-0">
        <div class="flex flex-col">
          <Title2 class="font-bold">{{ title }}</Title2>
          <Body2 class="mt-2">{{ $gettext('Упакуйте его, подпишите пакеты и укажите их количество') }}</Body2>
          <Hint v-if="order?.attr.is_pickup" bg-color="bg-blue-info" data-test="status card is-pickup" class="mt-4">
            <template #image>
              <BagAndCoffee />
            </template>
            <template #text> {{ $gettext('Заказ на самовывоз') }}</template>
            <template #sub-text> {{ $gettext('Уточните, нужны ли пакеты') }}</template>
          </Hint>
        </div>
      </Sector>

      <Sector>
        <Body2 v-if="packing.length === 0" data-test="hint text" class="font-medium whitespace-pre-wrap">
          {{ $gettext('Для склада не указаны настройки упаковки заказа в пакеты, обратитесь в поддержку') }}
        </Body2>
        <div class="flex flex-col gap-2">
          <SelectBagsCount
            v-for="packingSuggest in packing"
            :key="packingSuggest.product_id"
            :collected="collected"
            :packing-suggest="packingSuggest"
            :max-bags-count="calculateMaxBagsCount(suggests, packingSuggest)"
          />
        </div>
      </Sector>
    </div>

    <template #footer>
      <LayoutFooter>
        <ProductList :suggests="suggests" />
        <UiButton :is-disabled="!isCanClosePackSuggest" data-test="next-stage-btn" @click="nextStage">
          {{ $gettext('Далее') }}
        </UiButton>
      </LayoutFooter>
    </template>
  </Layout>
</template>

<script setup lang="ts">
import { getUltimaItemSuggests } from '@/fsd/entities/suggest/tools/suggestsFilters';
import BagAndCoffee from '@/fsd/shared/icons/bag-and-coffee/bag-and-coffee.vue';
import Hint from '@/fsd/shared/ui/hint/hint.vue';
import Sector from '@/fsd/shared/ui/sector/sector.vue';
import SelectBagsCount from '@/fsd/widgets/order/finish-order/SelectBags/SelectBagsCount.vue';
import { useClosePackingSuggests } from '@/fsd/widgets/order/finish-order/tools/useClosePackingSuggests';
import ProductList from '@/fsd/widgets/product-list/';
import Suggest from '@/models/Suggest';
import BaseOrder from '@/models/orders/BaseOrder';
import { useOrders } from '@/store/modules/orders';
import { $gettext } from '@/temp/plugins/gettext';
import Layout from '@/ui/common/layout.vue';
import LayoutFooter from '@/ui/common/layout/layout-footer.vue';
import Body2 from '@/ui/common/typo/body-2.vue';
import Title2 from '@/ui/common/typo/title-2.vue';
import UiButton from '@/ui/common/ui-button.vue';
import { computed, defineEmits, defineProps } from 'vue';
import {
  OrderFinishingDeltaNamesEnum,
  OrderFinishingMarkNamesEnum,
  useRUMOrderFinishingStep,
} from '../tools/useMeasureOrderFinish';

const props = defineProps<{
  packing: Suggest[];
  suggests: Suggest[];
  order_id: BaseOrder['order_id'];
}>();
const emit = defineEmits(['done']);

useRUMOrderFinishingStep({
  orderId: props.order_id,
  markName: OrderFinishingMarkNamesEnum.SELECT_BAGS,
  deltaName: OrderFinishingDeltaNamesEnum.SELECT_BAGS,
});

const { startMetric: startMetricResponse, finishMetric: finishMetricResponse } = useRUMOrderFinishingStep({
  orderId: props.order_id,
  markName: OrderFinishingMarkNamesEnum.SELECT_BAGS_RESPONSE,
  deltaName: OrderFinishingDeltaNamesEnum.SELECT_BAGS_RESPONSE,
  useLifecycleHooks: false,
});

const { collected, closePackingSuggests } = useClosePackingSuggests(props.packing);

const isCanClosePackSuggest = computed<boolean>(() => {
  return props.packing.every(pack => collected.get(pack.suggest_id) || collected.get(pack.suggest_id) === 0);
});

const order = computed(() => {
  return useOrders().orderById(props.order_id)!;
});

const title = $gettext('Заказ %{number}', { number: order.value.orderNumberForView });

const nextStage = async () => {
  startMetricResponse();
  const success = await closePackingSuggests();
  finishMetricResponse();

  if (success) emit('done');
};

const calculateMaxBagsCount = (suggests: Suggest[], packageSuggest: Suggest) => {
  if (packageSuggest.isUltimaPackage) {
    const ultimaPackageCount = getUltimaItemSuggests(suggests).length;
    if (ultimaPackageCount === 0) {
      collected.set(packageSuggest.suggest_id, 0);
      return 0;
    }
    return ultimaPackageCount + 1;
  }
  return suggests.reduce((acc, suggest) => {
    if (suggest.product?.type_accounting === 'true_weight') return acc + 1;
    return acc + suggest.count!;
  }, 1);
};
</script>
