<template>
  <div class="rounded py-0.5 px-1 flex justify-center items-center w-max" :class="containerClass">
    <Caption2 class="font-bold" color="white">{{ REPORT_ITEM_STATUS_TEXT[status] }}</Caption2>
  </div>
</template>

<script setup lang="ts">
import Caption2 from '@/ui/common/typo/caption-2.vue';
import { computed, defineProps } from 'vue';
import { REPORT_ITEM_STATUS, REPORT_ITEM_STATUS_TEXT } from './constants';

interface ReportItemProps {
  status: REPORT_ITEM_STATUS;
}

const props = defineProps<ReportItemProps>();

const containerClass = computed(() => ({
  'bg-green-normal': props.status === REPORT_ITEM_STATUS.ready,
  'bg-semantic-bg-invert opacity-50': props.status === REPORT_ITEM_STATUS.waiting,
  'bg-orange-normal': props.status === REPORT_ITEM_STATUS.error,
}));
</script>
