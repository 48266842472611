<template>
  <BadgeBase :size="size" :uppercase="uppercase" :container-class="badgeConfig[type].containerClass">
    <template v-if="badgeConfig[type].icon" #iconLeft>
      <component :is="badgeConfig[type].icon" height="100%" width="100%" />
    </template>
    <slot />
  </BadgeBase>
</template>

<script setup lang="ts">
import IconCircleCheck from '@/fsd/shared/icons/icon-circle-check.vue';
import BadgeBase from '@/fsd/shared/ui/BadgeBase/BadgeBase.vue';
import { BadgeSize } from '@/fsd/shared/ui/BadgeBase/types';
import type { Component } from 'vue';

type StatusType = 'complete' | 'waiting' | 'error' | 'custom' | 'processing';

interface BadgeConfig {
  containerClass: string;
  icon?: Component;
}

interface Props {
  size?: BadgeSize;
  type: StatusType;
  uppercase?: boolean;
}

const { size = 'large', uppercase = true } = defineProps<Props>();

const badgeConfig: Record<StatusType, BadgeConfig> = {
  complete: {
    containerClass: 'bg-lime-toxic text-white',
    icon: IconCircleCheck,
  },
  waiting: {
    containerClass: 'bg-orange-normal text-white',
  },
  error: {
    containerClass: 'bg-red-normal text-white',
  },
  custom: {
    containerClass: 'bg-gray-350 text-white',
  },
  processing: {
    containerClass: 'bg-blue-normal text-white',
  },
};
</script>
