<template>
  <div class="flex flex-col flex-1 items-center">
    <Body3 class="mb-2" :class="{ 'text-red-normal': problem }" color="text-secondary">
      <template v-if="problem"> {{ $gettext('На складе') }} </template>
      <template v-else> {{ $gettext('Количество') }} </template>
    </Body3>
    <div>
      <p
        class="text-primary font-medium text-xl leading-5"
        :class="{
          'text-red-normal': problem,
          'text-lime-toxic': suggest.status === 'done',
        }"
        data-test="product count value"
      >
        {{ productCountValue }}
      </p>
      <p
        v-if="suggest?.max_count"
        color="text-secondary"
        data-test="product count delta"
        class="text-secondary text-center text-sm leading-[14px]"
      >
        {{ delta }}
      </p>
    </div>
  </div>
</template>

<script lang="ts">
import { Problem } from '@/models/orders/BaseOrder';
import Suggest, { SuggestStatusEnum } from '@/models/Suggest';
import Body3 from '@/ui/common/typo/body-3.vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  components: { Body3 },
  props: {
    suggest: {
      type: Object as PropType<Suggest>,
      default: () => {
        return {} as Suggest;
      },
    },
    problem: {
      type: Object as PropType<Problem>,
      default: undefined,
    },
    collected: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    productCountValue(): string {
      let totalCount = this.suggest?.count || 0;
      let count = this.collected;

      if (this.problem && this.suggest) {
        totalCount = this.suggest.count! + this.problem.count!;
        count = this.suggest.count!;
      }

      if (this.suggest?.status === SuggestStatusEnum.done) {
        count = this.suggest.result_count || this.suggest.count!;
      }

      if (this.suggest?.product?.isTrueWeight) {
        const weight: number = this.suggest.count! || this.suggest.result_count!;
        if (weight > 1000) {
          return this.$gettext('%{count} из %{kg} кг', { count: String(count), kg: (weight / 1000).toFixed(3) });
        }
        return this.$gettext('%{count} из %{g} г', { count: String(count), g: String(weight) });
      }
      return this.$gettext('%{count} из %{all}', { count: String(count), all: String(totalCount) });
    },
    delta(): string {
      if (this.suggest?.product?.isTrueWeight) {
        if (this.suggest.isDeltaSymmetrical) {
          return this.$gettext('± %{g} г', { g: String(this.suggest.allowableError) });
        }

        const minCount = this.suggest.min_count ?? 0;
        const maxCount = this.suggest.max_count ?? 0;

        if (maxCount > 1000) {
          const min = (minCount / 1000).toFixed(3);
          const max = (maxCount / 1000).toFixed(3);

          return this.$gettext('%{min} кг - %{max} кг', { min, max });
        }
        return this.$gettext('%{min} г - %{max} г', { min: String(minCount), max: String(maxCount) });
      }

      if (!this.suggest?.allowableError) {
        return '';
      }
      return this.$gettext('± %{g} г', { g: String(this.suggest.allowableError) });
    },
  },
});
</script>
