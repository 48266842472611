<template>
  <div class="flex flex-col items-center justify-stretch h-screen bg-main">
    <BgLogin />
    <div class="flex items-center flex-[2_1_auto]" data-test="login-logo">
      <LogoB2b v-if="isB2B" color="text-primary" />
      <LogoDeli v-else-if="theme === 'deli'" />
      <Logo v-else />
    </div>
    <div v-if="!deviceId" class="flex flex-col items-center flex-[1_1_auto]">
      <InlineLoader />
      <div class="text-center mt-6 mx-10">
        <Caption1>
          {{ $gettext('Устаналиваем соединение с устройством') }}
        </Caption1>
        <br />
        <Caption1>
          {{ $gettext('Пожалуйста, подождите') }}
        </Caption1>
      </div>
    </div>
    <div v-else-if="tryLogin" class="flex flex-col items-center flex-[1_1_auto]">
      <InlineLoader />
      <div class="text-center mt-6 mx-10">
        <Caption1>
          {{ $gettext('Загружаем задания.') }}
        </Caption1>
        <br />
        <Caption1>
          {{ $gettext('Пожалуйста, подождите') }}
        </Caption1>
      </div>
    </div>
    <div v-else class="flex flex-col items-center flex-[1_1_auto]">
      <BarcodeLogin />
      <div class="text-center mt-6 mx-10">
        <Caption1>
          {{ $gettext('Отсканируйте личный штрих-код чтобы войти') }}
        </Caption1>
      </div>
    </div>
    <Version />
  </div>
</template>

<script lang="ts" setup>
import { isB2B } from '@/fsd/features/tools/useBranding';
import { rumSpaManager } from '@/fsd/shared/tools/RumSpaManager';
import { useRequestBarcode } from '@/hooks/useRequestBarcode';
import { useUser } from '@/store/modules/user';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import { Checkin } from '@/temp/services/checkin';
import InlineLoader from '@/ui/common/inline-loader.vue';
import Caption1 from '@/ui/common/typo/caption-1.vue';
import Version from '@/ui/home/version/version.vue';
import BarcodeLogin from '@/views/Login/BarcodeLogin.vue';
import BgLogin from '@/views/Login/BgLogin.vue';
import LogoB2b from '@/views/Login/Logos/logo-b2b.vue';
import LogoDeli from '@/views/Login/Logos/logo-deli.vue';
import Logo from '@/views/Login/Logos/logo.vue';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';

const userStore = useUser();
const router = useRouter();
const tryLogin = ref<boolean>(false);

const pageName = useRoute().name as string;
rumSpaManager.finishDataLoading(pageName);
rumSpaManager.startDataRendering(pageName);

const theme = computed((): string => {
  return userStore.theme;
});
const deviceId = computed((): string => {
  return userStore.device;
});

useRequestBarcode(async barcode => {
  if (!deviceId.value) return true;
  try {
    tryLogin.value = true;
    await userStore.login({
      barcode,
      device: deviceId.value,
    });
    if (Checkin.checkinRequired()) {
      await router.push({ name: 'checkin' });
      return false;
    }
    if (Checkin.checkinStoreConfirmRequired()) {
      const storeTitle = userStore.options?.constants.store.title;
      const userOnTheSameStore = await Notifications.confirmCenter({
        title: storeTitle
          ? $gettext('Вы всё ещё на складе «%{storeTitle}»?', { storeTitle })
          : $gettext('Вы находитесь на том же складе?'),
      });
      if (!userOnTheSameStore) {
        await router.push({ name: 'checkin' });
        return false;
      }
    }
    await router.push({ name: 'home' });
    return false;
  } catch (error) {
    console.error(error);
    tryLogin.value = false;
    return true;
  }
});
</script>
