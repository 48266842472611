import IconBlackUltima from '@/fsd/shared/icons/icon-black-ultima.vue';
import IconWarning from '@/fsd/shared/icons/warning/icon-warning.vue';
import IconArrowDownWeight from '@/temp/icons/icon-arrow-down-weight.vue';
import IconTrueMark from '@/temp/icons/icon-true-mark.vue';
import { $gettext } from '@/temp/plugins/gettext';
import { Notifications } from '@/temp/plugins/notification';
import IconFragileForModal from '@/utils/img/IconFragileForModal.vue';
import IconScalesForModal from '@/utils/img/IconScalesForModal.vue';
import { markRaw } from 'vue';

// ф-я открывает окошко для ввода пинкода

interface Options {
  onReturn?: () => void;
  text?: string;
}

export const needUpgradeRoleModal = async ({
  text = $gettext('Поднимите роль чтобы выполнить задание'),
}: Options = {}) => {
  return await Notifications.confirmCenter({
    title: $gettext('Недостаточно прав'),
    text,
    ok: $gettext('Поднять роль'),
    decline: $gettext('Вернуться'),
  });
};

export const erSuggestAcceptedCountModal = async () => {
  await Notifications.modal({
    title: $gettext('Введенное количество превышает допустимое'),
  });
};

export const erSuggestNonQuantsProduct = async () => {
  await Notifications.modal({
    title: $gettext('Нельзя пересчитать непорционный товар'),
    text:
      $gettext('Переместите данный товар с кухонной полки и выполните пересчет') + '\nER_SUGGEST_NON_QUANTS_PRODUCT',
  });
};

export const fragileModal = async () => {
  await Notifications.modal({
    title: $gettext('Хрупкие товары'),
    text:
      $gettext('Проверьте, чтобы у всех упаковок товаров были соблюдены следующие пункты:') +
      '\n · ' +
      $gettext('Целая упаковка') +
      '\n · ' +
      $gettext('Нет вмятин и трещин ') +
      '\n · ' +
      $gettext('Товар не вскрыт'),
    buttonText: $gettext('Продолжить'),
    iconTop: {
      icon: IconFragileForModal,
      position: 'center',
    },
  });
};

export const trueMarkModal = async () => {
  await Notifications.modal({
    title: $gettext('Нужно сканировать марку'),
    text: $gettext('Метка «Честный знак»'),
    buttonText: $gettext('Понятно'),
    iconTop: {
      icon: IconTrueMark,
      position: 'center',
    },
  });
};

export const weightModal = async () => {
  await Notifications.modal({
    title: $gettext('Весовой товар'),
    text: $gettext(
      'Взвесьте указанное количество товара на весах. Нажмите кнопку, которая соответствует товару, чтобы распечатать чек. Отсканируйте штрихкод указанный на чеке.',
    ),
    buttonText: $gettext('Понятно'),
    iconTop: {
      icon: IconScalesForModal,
      position: 'center',
    },
  });
};

export const needLessWeightModal = async (delta: string) => {
  await Notifications.modal({
    title: $gettext('Уменьшите вес товара на %{count} гр', { count: delta }),
    text: $gettext(
      'Вы взвесили слишком много, верните часть товара на полку, взвесьте заново и отсканируйте новый чек.',
    ),
    buttonText: $gettext('Хорошо'),
    iconTop: {
      icon: markRaw(IconArrowDownWeight),
      position: 'left',
    },
  });
};

export const ultimaModal = async () => {
  await Notifications.universalModal({
    title: $gettext('Посылку надо переложить в упаковку Ultima'),
    text: $gettext('При упаковке заказа аккуратно вскройте коробку и упакуйте её по инструкции'),
    buttons: [
      {
        title: $gettext('Закрыть'),
        color: 'secondary',
        onClick: () => undefined,
      },
    ],
    position: 'bottom',
    iconTop: {
      icon: markRaw(IconBlackUltima),
      position: 'left',
    },
  });
};

export const overweightModal = async () => {
  await Notifications.universalModal({
    title: $gettext('При сборке этого товара не должно быть перевеса'),
    text: $gettext('Иначе не получится завершить сборку заказа'),
    buttons: [
      {
        title: $gettext('Закрыть'),
        color: 'secondary',
        onClick: () => undefined,
      },
    ],
    position: 'bottom',
    iconTop: {
      icon: markRaw(IconWarning),
      position: 'left',
      props: {
        bgColor: 'red-normal',
      },
    },
  });
};
